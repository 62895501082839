import { types } from 'mobx-state-tree';

const TripModel = types
  .model('TripModel', {
    id: types.optional(types.integer, 0),
    key: types.optional(types.integer, 0),
    user_id: types.optional(types.maybeNull(types.integer), 0),
    article_type_id: types.optional(types.maybeNull(types.integer), 0),
    contact_id: types.optional(types.maybeNull(types.integer), 0),
    deliverer_code: types.optional(types.maybeNull(types.string), ''),
    deliverer_name: types.optional(types.maybeNull(types.string), ''),
    deliverer_description: types.optional(types.maybeNull(types.string), ''),
    intern_code: types.optional(types.maybeNull(types.string), ''),
    intern_name: types.optional(types.maybeNull(types.string), ''),
    intern_description: types.optional(types.maybeNull(types.string), ''),
    purchase_price: types.optional(types.maybeNull(types.number), 0),
    sale_price: types.optional(types.maybeNull(types.string), ''),
    purchase_total: types.optional(types.maybeNull(types.integer), 0),
    sale_total: types.optional(types.maybeNull(types.integer), 0),
    currency_id: types.optional(types.maybeNull(types.integer), 0),
    tax_income_id: types.optional(types.maybeNull(types.integer), 0),
    tax_id: types.optional(types.maybeNull(types.integer), 0),
    tax_expense_id: types.optional(types.maybeNull(types.integer), 0),
    unit_id: types.optional(types.maybeNull(types.number), 0),
    is_stock: types.optional(types.maybeNull(types.boolean), false),
    stock_id: types.optional(types.maybeNull(types.integer), 0),
    stock_place_id: types.optional(types.maybeNull(types.integer), 0),
    stock_nr: types.optional(types.maybeNull(types.integer), 0),
    stock_min_nr: types.optional(types.maybeNull(types.integer), 0),
    stock_reserved_nr: types.optional(types.maybeNull(types.integer), 0),
    stock_available_nr: types.optional(types.maybeNull(types.integer), 0),
    stock_picked_nr: types.optional(types.maybeNull(types.integer), 0),
    stock_disposed_nr: types.optional(types.maybeNull(types.integer), 0),
    stock_ordered_nr: types.optional(types.maybeNull(types.integer), 0),
    width: types.optional(types.maybeNull(types.number), 0),
    height: types.optional(types.maybeNull(types.number), 0),
    weight: types.optional(types.maybeNull(types.number), 0),
    volume: types.optional(types.maybeNull(types.number), 0),
    html_text: types.optional(types.maybeNull(types.string), ''),
    remarks: types.optional(types.maybeNull(types.string), ''),
    delivery_price: types.optional(types.maybeNull(types.number), 0),
    article_group_id: types.optional(types.maybeNull(types.integer), 0)
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default TripModel;
