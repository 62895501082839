import { ContactAndTripManagementUtilities } from '@app_modules/ContactAndTrip/model';
import { ContactAndTripState, ContactState, TripState } from '@app_modules/ContactAndTrip/model';
import { types } from 'mobx-state-tree';

const AppStore = {
  contact: types.optional(ContactState, {}),
  trip: types.optional(TripState, {}),
  contactAndTrip: types.optional(ContactAndTripState, {}),
  ContactAndTripManagementUtilities: types.optional(ContactAndTripManagementUtilities, {})
};

export default AppStore;
