import { types, flow } from 'mobx-state-tree';
import ContactAndTripModel from './ContactAndTripModel';
import ApplyRootCrud from '@core_state_management/Helpers/RootCrud';
import axios from 'axios';

const API_PATH = '/api/contact_and_trip';
const API_EXPORT_PATH = '/api/contact_and_trip/export_csv';

export default types
  .model('ContactAndTripState', {
    state: types.optional(types.array(ContactAndTripModel), []),
    singleState: types.optional(ContactAndTripModel, {}),
    total: types.optional(types.number, 0),
    loading: types.optional(types.boolean, false),
    isCreating: types.optional(types.boolean, false)
  })
  .actions((self) => ({
    ...ApplyRootCrud(API_PATH, self),
    exportData: flow(function* (selectedId) {
      return yield axios.get(API_EXPORT_PATH, { params: { selectedId: selectedId } });
    }),

    updateBexioData: flow(function* () {
      try {
        yield axios.get(`/api/trip/clone`);
        yield axios.get(`/api/contact/clone`);
        yield axios.get(`/api/salutation/clone`);
        yield axios.get(`/api/title/clone`);
        yield axios.get(`/api/contact_type/clone`);

        return [{ message: 'Contact_And_Trip_#Succesfully updated BEXIO data' }, null];
      } catch (error) {
        console.log(error);
        return [null, error];
      }
    }),

    getCSV: flow(function* () {
      return yield axios({
        url: '/public/export/export_file.csv', //your url
        method: 'GET',
        responseType: 'blob' // important
      });
    }),

    deleteFile: flow(function* () {
      yield axios.delete('/api/contact_and_trip/delete_csv');
    })
  }))
  .views((self) => ({}));
