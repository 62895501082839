import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { inject, observer } from 'mobx-react';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';
import { ContactAndTripTable, ContactAndTripHeader } from '@app_modules/ContactAndTrip/components';

function ContactAndTrip({ store }) {
  const { t } = useTranslation('common');
  const [form] = Form.useForm();

  const [isDeleteVisible, setIsDeleteVisible] = useState(false);

  return (
    <div>
      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <ContactAndTripHeader
          form={form}
          isDeleteVisible={isDeleteVisible}
          setIsDeleteVisible={setIsDeleteVisible}
        />
      </Suspense>

      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <ContactAndTripTable form={form} setIsDeleteVisible={setIsDeleteVisible} />
      </Suspense>
    </div>
  );
}

export default inject('store')(observer(ContactAndTrip));
