import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

/**APP IMPORTS */
import { ContactAndTrip } from '@app_modules/ContactAndTrip/view';

/**APP CORE */
import '@core_assets/css/exact-styles.css';
import '@core_assets/css/App.css';
import '@core_assets/css/App.less';
import '@core_assets/css/antd-custom.css';

import { PrivateRoute, Routes } from '@core_routing/';
import {
  AdminSettings,
  AdminLayout,
  BrowserVersionCheck,
  SignInLayout,
  Dashboard,
  UserManagement,
  Login,
  ResetPassword,
  ChangePassword,
  Error404,
  Translation
} from '@core_modules/';
import UserActivityTable from '@core_modules/Dashboard/components/UserActivityTable';

function App({ store }) {
  let { login } = store;
  const [browserModalVisible, setBrowserModalVisible] = useState(false);

  // eslint-disable-next-line
  useEffect(store.translations.GET_LOCALES, []);

  return (
    !login.isLoading && (
      <>
        <Router>
          <Switch>
            <PrivateRoute
              exact
              path={Routes.HOME_ROUTE}
              allowed={['Administrator', 'Normal User']}
              isAuthorized={true}
              login={login}
              Component={ContactAndTrip}
              Layout={AdminLayout}
            />
            <PrivateRoute
              exact
              path={Routes.CONTACT_AND_TRIP_ROUTE}
              allowed={['Administrator', 'Normal User']}
              isAuthorized={true}
              login={login}
              Component={ContactAndTrip}
              Layout={AdminLayout}
            />
            <PrivateRoute
              exact
              path={'/verify'}
              allowed={['Administrator', 'Normal User']} //DECLARE ALL TYPES OF USERS
              isAuthorized={true}
              login={login}
              isVerify={true}
              custom={[
                { if: login.user_type_id === 1, redirect: '/' },
                { if: login.user_type_id === 2, redirect: '/' }
              ]}
            />
            <PrivateRoute
              exact
              path={'/test'}
              allowed={['Administrator']}
              isAuthorized={true}
              login={login}
              Component={UserActivityTable}
              // Layout={AdminLayout}
            />
            <PrivateRoute
              exact
              path={Routes.TRANSLATION_VIEW_ROUTE}
              allowed={['Administrator']}
              isAuthorized={true}
              login={login}
              Component={Translation}
              Layout={AdminLayout}
            />
            <PrivateRoute
              exact
              path={Routes.USER_MANAGEMENT_ROUTE}
              allowed={['Administrator']}
              isAuthorized={true}
              login={login}
              Component={UserManagement}
              Layout={AdminLayout}
            />
            <PrivateRoute
              exact
              path={Routes.LOGIN_ROUTE}
              Component={Login}
              isAuthorized={false}
              login={login}
              allowed={['GUEST']}
              Layout={SignInLayout}
            />
            <PrivateRoute
              exact
              path={Routes.RESET_PASSWORD_ROUTE}
              Component={ResetPassword}
              isAuthorized={false}
              login={login}
              allowed={['GUEST']}
              Layout={SignInLayout}
            />
            <PrivateRoute
              exact
              path={Routes.CHANGE_PASSWORD_ROUTE}
              Component={ChangePassword}
              isAuthorized={false}
              login={login}
              allowed={['GUEST']}
              Layout={SignInLayout}
            />
            <PrivateRoute
              exact
              path={Routes.ADMIN_SETTINGS_ROUTE}
              Component={AdminSettings}
              isAuthorized={true}
              login={login}
              allowed={['Administrator']}
              Layout={AdminLayout}
            />
            <Route component={Error404} />
          </Switch>
        </Router>
        <BrowserVersionCheck
          browserModalVisible={browserModalVisible}
          setBrowserModalVisible={setBrowserModalVisible}
        />
      </>
    )
  );
}

export default inject('store')(observer(App));
