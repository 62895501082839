import React from 'react';
import { AimOutlined } from '@ant-design/icons';
import Routes from '@app_routing/Routes';

const AppMenus = [
  {
    key: '3',
    icon: <AimOutlined />,
    route: Routes.CONTACT_AND_TRIP_ROUTE,
    menuName: 'Contact_And_Trip_#Contact And Trip'
  }
];

export default AppMenus;
