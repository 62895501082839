import { types, cast } from 'mobx-state-tree';
import ContactModel from './ContactModel';

const ContactAndTripManagementUtilities = types
  .model('ContactAndTripManagementUtilities', {
    showAddContactAndTripModal: types.optional(types.boolean, false),
    showCsvExportModal: types.optional(types.boolean, false),
    search: types.optional(types.string, ''),
    props: types.optional(types.array(types.string), [
      'arrival',
      'departure',
      'doubleRoomORSingleRoom',
      'rent',
      'passportOrId',
      'passportOrIdNumber',
      'remark',
      'hometown',
      'nationality',
      'gender',
      'size',
      'issuedOn',
      'issuedWhere',
      'validUntil',
      'additionalInfo1',
      'additionalInfo2',
      'additionalInfo3'
    ]),
    isUpdate: types.optional(types.boolean, false),
    updateId: types.optional(types.integer, 0),
    selectedRowsKeys: types.optional(types.array(types.integer), []),
    isDownloading: types.optional(types.boolean, false),
    currentPage: types.optional(types.integer, 1),
    //Csvexport modal utilities
    defaultCheckedList: types.optional(types.array(types.string), []),
    plainOptions: types.optional(
      types.array(types.model({ label: types.string, value: types.string })),
      []
    ),
    selectedId: types.optional(types.array(types.string), []),
    checkedList: types.optional(types.array(types.string), []),
    indeterminate: types.optional(types.boolean, false),
    isUpdateingBexioLoading: types.optional(types.boolean, false),
    checkAll: types.optional(types.boolean, false)
  })
  .views((self) => ({}))
  .actions((self) => ({
    setToggleShowAddOrUpdateContactAndTripModal(isUpdate = false) {
      self.isUpdate = isUpdate ? true : false;
      self.showAddOrUpdateContactAndTripModal = !self.showAddOrUpdateContactAndTripModal;
    },
    getDateFormat(locale) {
      switch (locale) {
        case 'de':
          return 'DD.MM.YYYY';
        default:
          return 'DD-MM-YYYY';
      }
    },

    setIsUpdateingBexioLoading() {
      self.isUpdateingBexioLoading = !self.isUpdateingBexioLoading;
    },
    setToggleShowCsvExportModal() {
      self.showCsvExportModal = !self.showCsvExportModal;
    },

    setSelectedRowsKeys(selectedRowsKeys) {
      self.selectedRowsKeys = cast(selectedRowsKeys);
    },
    setIsDownloading(isDownloading) {
      self.isDownloading = isDownloading;
    },

    setSearch(searchValue) {
      self.search = searchValue;
    },
    setUpdateId(id) {
      self.updateId = id;
    },
    setCheckAll(isCheckAll) {
      self.checkAll = isCheckAll;
    },
    setIndeterminate(isIndeterminate) {
      self.indeterminate = isIndeterminate;
    },
    setCheckedList(isCheckedList) {
      self.checkedList = isCheckedList;
      self.selectedId = isCheckedList;
    },
    setSelectedId(isSelectedId) {
      self.selectedId = isSelectedId;
    },
    setPlainOptions(isPlainOptions) {
      try {
        if (isPlainOptions.length != 0) {
          self.plainOptions = isPlainOptions.map((data) => {
            return { label: data.intern_name, value: `${data.id}` };
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }));

export default ContactAndTripManagementUtilities;
