import { types } from 'mobx-state-tree';

const ContactModel = types
  .model('ContactModel', {
    id: types.optional(types.integer, 0),
    key: types.optional(types.integer, 0),
    contact_type_id: types.optional(types.maybeNull(types.integer), 0),
    name_1: types.optional(types.maybeNull(types.string), ''),
    name_2: types.optional(types.maybeNull(types.string), ''),
    salutation_id: types.optional(types.maybeNull(types.integer), 0),
    salutation_form: types.optional(types.maybeNull(types.string), ''),
    title_id: types.optional(types.maybeNull(types.integer), 0),
    birthday: types.optional(types.maybeNull(types.string), ''),
    address: types.optional(types.maybeNull(types.string), ''),
    postcode: types.optional(types.maybeNull(types.string), ''),
    city: types.optional(types.maybeNull(types.string), ''),
    country_id: types.optional(types.maybeNull(types.integer), 0),
    mail: types.optional(types.maybeNull(types.string), ''),
    mail_second: types.optional(types.maybeNull(types.string), ''),
    phone_fixed: types.optional(types.maybeNull(types.string), ''),
    phone_fixed_second: types.optional(types.maybeNull(types.string), ''),
    phone_mobile: types.optional(types.maybeNull(types.string), ''),
    fax: types.optional(types.maybeNull(types.string), ''),
    url: types.optional(types.maybeNull(types.string), ''),
    skype_name: types.optional(types.maybeNull(types.string), ''),
    remarks: types.optional(types.maybeNull(types.string), ''),
    language_id: types.optional(types.maybeNull(types.integer), 0),
    is_lead: types.optional(types.maybeNull(types.boolean), false),
    contact_group_ids: types.optional(types.maybeNull(types.string), ''),
    contact_branch_ids: types.optional(types.maybeNull(types.string), ''),
    user_id: types.optional(types.maybeNull(types.integer), 0),
    owner_id: types.optional(types.maybeNull(types.integer), 0)
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default ContactModel;
