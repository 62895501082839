import { types, cast } from 'mobx-state-tree';
import ContactModel from './ContactModel';
import DropdownModel from './DropdownModel';
import TripModel from './TripModel';

const ContactAndTripModel = types
  .model('ContactAndTripModel', {
    id: types.optional(types.integer, 0),
    key: types.optional(types.integer, 0),
    contactId: types.optional(types.integer, 0),
    tripId: types.optional(types.integer, 0),
    arrival: types.optional(types.maybeNull(types.string, '')),
    departure: types.optional(types.maybeNull(types.string, '')),
    doubleRoomORSingleRoom: types.optional(types.maybeNull(types.string, '')),
    rent: types.optional(types.maybeNull(types.string, '')),
    passportOrId: types.optional(types.maybeNull(types.string, '')),
    passportOrIdNumber: types.optional(types.maybeNull(types.string, '')),
    remark: types.optional(types.maybeNull(types.string, '')),
    refund: types.optional(types.maybeNull(types.number, 0)),
    name_1: types.optional(types.maybeNull(types.string), ''),
    name_2: types.optional(types.maybeNull(types.string), ''),
    tripName: types.optional(types.maybeNull(types.string), ''),
    tripDescription: types.optional(types.maybeNull(types.string), ''),
    //passport info fields here
    hometown: types.optional(types.maybeNull(types.string, '')),
    nationality: types.optional(types.maybeNull(types.string, '')),
    gender: types.optional(types.maybeNull(types.string, '')),
    size: types.optional(types.maybeNull(types.string, '')),
    issuedOn: types.optional(types.maybeNull(types.string, '')),
    issuedWhere: types.optional(types.maybeNull(types.string, '')),
    validUntil: types.optional(types.maybeNull(types.string, '')),
    additionalInfo1: types.optional(types.maybeNull(types.string, '')),
    additionalInfo2: types.optional(types.maybeNull(types.string, '')),
    additionalInfo3: types.optional(types.maybeNull(types.string, '')),
    //passport info fields here -- end
    Contact: types.optional(types.maybeNull(ContactModel), {}),
    Trip: types.optional(TripModel, {}),
    double_single_room_list: types.optional(types.array(DropdownModel), []),
    rent_list: types.optional(types.array(DropdownModel), []),
    passport_or_id_list: types.optional(types.array(DropdownModel), []),
    passport_info_list: types.optional(types.array(DropdownModel), [])
  })
  .views((self) => ({}))
  .actions((self) => ({
    setDropdownList(type, list) {
      self[`${type}_list`] = cast(list);
    }
  }));

export default ContactAndTripModel;
