import { types, flow } from 'mobx-state-tree';
import TripModel from './TripModel';
import ApplyRootCrud from '@core_state_management/Helpers/RootCrud';
import axios from 'axios';

const API_PATH = '/api/trip';

export default types
  .model('TripState', {
    state: types.optional(types.array(TripModel), []),
    singleState: types.optional(TripModel, {}),
    total: types.optional(types.number, 0),
    loading: types.optional(types.boolean, false),
    isCreating: types.optional(types.boolean, false)
  })
  .actions((self) => ({
    ...ApplyRootCrud(API_PATH, self)
  }))
  .views((self) => ({}));
