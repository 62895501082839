import { lazy } from 'react';

const ContactAndTripHeader = lazy(() => import('./ContactAndTripHeader'));
const ContactAndTripTable = lazy(() => import('./ContactAndTripTable'));
const AddOrUpdateContactAndTripModal = lazy(() => import('./AddOrUpdateContactAndTripModal'));
const ContactAndTripForm = lazy(() => import('./ContactAndTripForm'));
const PassportInfoForm = lazy(() => import('./PassportInfoForm'));
const CsvExportModal = lazy(() => import('./CsvExportModal'));

export {
  ContactAndTripHeader,
  ContactAndTripTable,
  AddOrUpdateContactAndTripModal,
  CsvExportModal,
  ContactAndTripForm,
  PassportInfoForm
};
